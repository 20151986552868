<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Tambah Jenis Produk Baru
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

        <!-- BODY -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >

           <!-- Nama Produk -->
           <validation-provider
              #default="validationContext"
              name="Nama Pengguna"
              rules="required"
            >
           <b-form-group
             label="Nama Pengguna"
             label-for="name"
           >
             <b-form-input
               id="name"
               v-model="userData.name"
               autofocus
               :state="getValidationState(validationContext)"
               trim
               placeholder="Nama Pengguna"
             />

             <b-form-invalid-feedback>
               {{ validationContext.errors[0] }}
             </b-form-invalid-feedback>
           </b-form-group>
         </validation-provider>

           <!-- Email -->
           <validation-provider
              #default="validationContext"
              name="Email"
              rules="required"
            >
           <b-form-group
             label="email"
             label-for="email"
           >
             <b-form-input
               id="email"
               v-model="userData.email"
               autofocus
               :state="getValidationState(validationContext)"
               trim
               placeholder="Email"
             />

             <b-form-invalid-feedback>
               {{ validationContext.errors[0] }}
             </b-form-invalid-feedback>
           </b-form-group>
         </validation-provider>

         <!-- Deskripsi -->

         <validation-provider
              #default="validationContext"
              name="username"
              rules="required"
            >
           <b-form-group
             label="username"
             label-for="username"
           >
             <b-form-input
               id="username"
               v-model="userData.username"
               autofocus
               :state="getValidationState(validationContext)"
               trim
               placeholder="username"
             />

             <b-form-invalid-feedback>
               {{ validationContext.errors[0] }}
             </b-form-invalid-feedback>
           </b-form-group>
         </validation-provider>

          <!-- Password -->
          <validation-provider
            #default="validationContext"
            name="Password"
            rules="required"
          >
            <b-form-group
              label="password"
              label-for="unit"
            >
              <b-form-input
                id="password"
                v-model="userData.password"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Roles -->
          <validation-provider
          #default="validationContext"
          name="Role Pengguna"
          
        >
          <b-form-group
            label="Pilih Role Pengguna"
            label-for="roles"
            :state="getValidationState(validationContext)"
          >
            <v-select
              v-model="roleSelected.role"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="roleOptions"
              :clearable="false"
              input-id="roles"
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Tambah
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Batal
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { BSidebar, BForm, BFormGroup, BFormInput,BFormRadioGroup, BFormInvalidFeedback, BButton, BFormDatepicker, BFormTimepicker } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

import store from '@/store'
import axios from '@axios';

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    BFormTimepicker,
    BFormInvalidFeedback,
    BButton,
    BFormRadioGroup,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      milkConditionOptions: [],
      roleOptions: [],
    }
  },
  created() {
    this.$http.get('users/roles').then(response => {
      const roles = response.data.data
      roles.forEach(role => {
        this.roleOptions.push(
          {
            label: role.name,
            value: role.name,
          }
          )
      })
    })
  },
  setup(props, { emit }) {

    // use Toast
    const toast = useToast()

    // setup json post user data
    const blankUserData = {
      username: null,
      password: 'smkn1tulungbisa',
      email: null,
      role: null,
    }
    
    // define userData from JSON data to ref variable
    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))

    const blankRoleSelected = {
      role: null,
    }

    const roleSelected = ref(JSON.parse(JSON.stringify(blankRoleSelected)))

    // when click Cancle button
    const resetUserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
      roleSelected.value = JSON.parse(JSON.stringify(blankRoleSelected))
    }

    // when click Add button
    const onSubmit = async () => {
      userData.value.role = roleSelected.value.role.value

      await store.dispatch('app-users/addUser', userData.value)
      .then((response) => {
        console.log(response)
        if(response.data.status == 417){
          toast({
          component: ToastificationContent,
          props: {
            title: 'Gagal menambah pengguna baru',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
        
        toast({
          component: ToastificationContent,
          props: {
            title: 'Berhasil menambah pengguna baru',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        emit('refetch-data-summary')
        emit('update:is-add-new-user-sidebar-active', false)

      })
  }

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetUserData)

    return {
      userData,
      roleSelected,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';

#add-new-goat-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
