<template>
    <div>
        <user-add-new
            :is-add-new-user-sidebar-active.sync="
                isAddNewUserSidebarActive
            "
            @refetch-data-summary="refetchUsers"
        />
        <product-category-edit
            :is-edit-product-category-sidebar-active.sync="
                isEditProductCategorySidebarActive
            "
            :productCategoryData="productDataEdit"
            @refetch-data-summary="refetchUsers"
        />

        <!-- List produksi susu group by kambing -->
        <b-card no-body class="mb-0 mt-3">
            <!-- Table Top -->
            <b-row class="pt-2 pl-2 pr-2">
                <b-col
                    cols="12"
                    md="6"
                    class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                >
                    <h4>Data Pengguna</h4>
                </b-col>

                <b-col
                    cols="12"
                    md="6"
                    class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
                >
                    <b-button
                        variant="primary"
                        @click="
                            isAddNewUserSidebarActive =
                                !isAddNewUserSidebarActive
                        "
                    >
                        <span class="text-nowrap">+ Tambah Data</span>
                    </b-button>
                </b-col>
            </b-row>
            <hr />
            <b-row class="pl-2 pr-2 pb-2">
                <!-- Per Page -->
                <b-col
                    cols="12"
                    md="3"
                    class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                >
                    <b-form-input
                        v-model="searchQueryRawMilkSummary"
                        class="d-inline-block mr-1"
                        placeholder="Search..."
                    />
                </b-col>

                <!-- Search -->
                <b-col cols="12" md="6">
                    <div
                        class="d-flex align-items-center justify-content-end"
                    ></div>
                </b-col>
            </b-row>

            <b-table
                ref="refProductCategory"
                class="position-relative"
                :items="fetchProductCategories"
                responsive
                :fields="tableColumnProductCategory"
                primary-key="id"
                :sort-by.sync="sortByProductCategory"
                show-empty
                empty-text="No matching records found"
                :sort-desc.sync="isSortDirDescProductCategory"
            >
                <template #cell(nama_produk)="data">
                    <b-media vertical-align="center">
                        <template #aside>
                          <b-avatar
                            size="32"
                            :src="data.item.icon_produk"
                            :variant="`light-secondary`"
                          />
                        </template>
                        <p
                          class="font-weight-bold d-block text-nowrap"
                        >
                          {{ data.item.nama_produk }}
                        </p>
                    </b-media>
                </template>

                <template #cell(aksi)="data">
                    <b-dropdown
                        variant="link"
                        no-caret
                        :right="$store.state.appConfig.isRTL"
                    >
                        <template #button-content>
                            <feather-icon
                                icon="MoreVerticalIcon"
                                size="16"
                                class="align-middle text-body"
                            />
                        </template>
                        <b-dropdown-item
                            :to="{
                                name: 'milkProductDetail',
                                params: { id: data.item.id },
                            }"
                        >
                            <feather-icon icon="FileTextIcon" />
                            <span class="align-middle ml-50"
                                >Detail User</span
                            >
                        </b-dropdown-item>

                        <b-dropdown-item @click="handleEditData(data.item)">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Edit</span>
                        </b-dropdown-item>

                        <b-dropdown-item @click="handleDelete(data.item.id)">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50" >Delete</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
            </b-table>
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                        <span class="text-muted"
                            >Showing {{ dataMetaRawMilkSummary.from }} to
                            {{ dataMetaRawMilkSummary.to }} of
                            {{ dataMetaRawMilkSummary.of }} entries</span
                        >
                    </b-col>
                    <!-- Pagination -->
                    <!-- <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPageRawMilkSummary"
              :total-rows="totalGoatsRawMilkSummary"
              :per-page="perPageRawMilkSummary"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col> -->
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import useUserList from "./useUserList.js";
import userStoreModule from "./userStoreModule.js";
import UserAddNew from "./UserAddNew.vue";
import ProductCategoryEdit from "./ProductCategoryEdit.vue";

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    components: {
        UserAddNew,
        ProductCategoryEdit,

        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,

        vSelect,
    },
    setup() {
        // use Toast
        const toast = useToast()

        const USER_STORE_MODULE = "app-users";

        // Register module
        if (!store.hasModule(USER_STORE_MODULE))
            store.registerModule(
                USER_STORE_MODULE,
                userStoreModule
            );

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(USER_STORE_MODULE))
                store.unregisterModule(USER_STORE_MODULE);
        });

        const isAddNewUserSidebarActive = ref(false);
        const isEditProductCategorySidebarActive = ref(false);

        const {
            fetchProductCategories,
            tableColumnProductCategory,
            perPageRawMilkSummary,
            currentPageRawMilkSummary,
            totalGoatsRawMilkSummary,
            dataMetaRawMilkSummary,
            perPageOptionsRawMilkSumarry,
            searchQueryRawMilkSummary,
            sortByProductCategory,
            isSortDirDescProductCategory,
            refProductCategory,

            refetchUsers,
        } = useUserList();


        const handleDelete = async (idCategory) => {
            await store.dispatch('app-product-category/deleteProductCategory', {id: idCategory}).then((response) => {
                if(response.data.status == 500){
                        toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Gagal menghapus kategori produk',
                                icon: 'AlertTriangleIcon',
                                variant: 'danger',
                        },
                    })
                }
            })

            refetchUsers();
            toast({
                component: ToastificationContent,
                props: {
                    title: 'Berhasil menghapus kategori produk',
                    icon: 'CheckIcon',
                    variant: 'success',
                },
            })
        }

        const blankProductCategory = {
            id: null,
            category: null,
            unit: null,
            description: null,
            product_picture: '',
        }
    
        // define productCategory from JSON data to ref variable
        const productDataEdit = ref(JSON.parse(JSON.stringify(blankProductCategory)))

        const handleEditData = (productCategoryData) => {
            isEditProductCategorySidebarActive.value = !isEditProductCategorySidebarActive.value
            productDataEdit.value.id = productCategoryData.id;
            productDataEdit.value.category = productCategoryData.nama_produk;
            productDataEdit.value.unit = productCategoryData.satuan;
            productDataEdit.value.description = productCategoryData.deskripsi;
        }

        return {
            // Sidebar
            isAddNewUserSidebarActive,
            isEditProductCategorySidebarActive,
            handleEditData,
            productDataEdit,

            fetchProductCategories,
            tableColumnProductCategory,
            perPageRawMilkSummary,
            currentPageRawMilkSummary,
            totalGoatsRawMilkSummary,
            dataMetaRawMilkSummary,
            perPageOptionsRawMilkSumarry,
            searchQueryRawMilkSummary,
            sortByProductCategory,
            isSortDirDescProductCategory,
            refProductCategory,

            refetchUsers,
            handleDelete,
        };
    },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}
</style>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-select.scss";
</style>
