import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/users')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/users', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editProductCategory(ctx, productCategory) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/milk/productions/categories/${productCategory.id}`, productCategory)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteProductCategory(ctx, category) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/milk/productions/categories/${category.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
